import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CodeMirror, { Editor } from "@uiw/react-codemirror";
import "./App.css";
import { IchibotClientOpts } from "./client/ichibotclient";
import MasterClient from "./client/masterclient";
import { flatten } from "ramda";
import { getInitInstrument, Logger } from "./shared/util";
import { CONSOLE_COLORS, SETTINGS_KEY } from "./client/constants";
import { ExchangeLabel, SUPPORTED_EXCHANGES } from "./shared/types";
import { Row, Col, Layout, Menu, Typography } from "antd";
import { modifyVars } from "less";
import { APP_VERSION } from "./shared/ichibotrpc_types";
import { decodeMultiple } from "cbor-x";

const { Header, Footer } = Layout;
const { Text } = Typography;

modifyVars({});

const wsUrlA = "wss://beta.ichibot.trade:2053";
const debug = false;

let LOG_TIMESTAMPS = true;
const settings = localStorage.getItem(`/${SETTINGS_KEY}`);
if (settings) {
  try {
    const { logTimestamps } = JSON.parse(settings);
    if (typeof logTimestamps === "boolean") {
      LOG_TIMESTAMPS = logTimestamps;
    }
  } catch { }
}

const LEFT_COL_WIDTH = LOG_TIMESTAMPS ? "15em" : "3em";

const existsSync = (filename: string) =>
  localStorage.getItem(filename) !== null;
const readFileSync = (filename: string): string =>
  localStorage.getItem(filename) ?? "";
const writeFileSync = (filename: string, data: string): void => {
  localStorage.setItem(filename, data);
};

let logLineCounter = 0;

type LogLine = [number, string, string];

function useLogger(maxLines: number = 100): [LogLine[], Logger] {
  const lines = useMemo<LogLine[]>(() => [], []);

  const [currentLines, setCurrentLines] = useState<LogLine[]>([]);

  const pushLine = useCallback(
    (line: string) => {
      lines.push([
        logLineCounter++,
        LOG_TIMESTAMPS ? new Date().toLocaleString() : ">",
        line,
      ]);

      while (lines.length > maxLines) {
        lines.shift();
      }

      setCurrentLines(lines.slice());
    },
    [lines, maxLines],
  );

  const logger: Logger = useMemo(
    () => ({
      log: (...args: any[]) =>
        pushLine(args.join(" ").replace(CONSOLE_COLORS.Dim, "")),
      dir: (arg: { [k: string]: any }) => {
        for (const k of Object.keys(arg)) {
          logger.log(`${k}: ${arg[k]}`);
        }
      },
      warn: (...args: any[]) => logger.log(...args),
      error: (...args: any[]) => logger.log(...args),
      debug: (...args: any[]) => {
        if (process.env.DEBUG) {
          logger.log(...args);
        }
      },
    }),
    [pushLine],
  );

  return [currentLines, logger];
}

function Ichibot(props: { showEditor?: boolean; miniView?: true }) {
  const [logLines, output] = useLogger();

  // Note: the following is a straight copy from the client/index.ts file. If changes are made either way, copy them over.
  // If cmd is null this is a removal
  const saveCmdToInit = useCallback(
    (
      exchange: ExchangeLabel,
      contextSymbol: string,
      lineMatchers: Array<string | null>,
      cmd: string | null,
      reverse = false,
      multiple = false,
    ): void => {
      if (!exchange) {
        // Legacy. Currently not possible to create auth without exchange but old data may have it so
        throw new Error(
          `Could not find the name of the exchange from configuration`,
        );
      }

      const filename = getInitFilePath(exchange);

      const symFriendlyName = contextSymbol === "*" ? "global" : contextSymbol;
      if (cmd) {
        output.log(
          `Writing command "${cmd}" to ${symFriendlyName} initialization steps.`,
        );
      } else {
        output.log(
          `Removing command "${lineMatchers[0]} ${lineMatchers[1]}" from ${symFriendlyName} initialization steps.`,
        );
      }

      const cmdWords = cmd
        ?.split(/\s+/)
        .map((a) => a.trim())
        .filter((a) => a !== "");

      const initLines = existsSync(filename)
        ? readFileSync(filename)
          .toString()
          .split("\n")
          .map((l) => l.trim())
        : [];

      type LinesPerSym = { sym: string; lines: string[] };
      const linesPerSym: Array<LinesPerSym> = [];
      let currentInstrument = getInitInstrument(exchange);

      let currentSymBlock: { sym: string; lines: string[] } = {
        sym: currentInstrument,
        lines: [],
      };

      for (const l of initLines) {
        const args = l.split(/\s+/).map((a) => a.trim());

        if (
          args[0] === "instrument" &&
          args.length > 1 &&
          args[1].toUpperCase() !== currentInstrument
        ) {
          linesPerSym.push(currentSymBlock);
          currentSymBlock = { sym: args[1].toUpperCase(), lines: [] };
          currentInstrument = currentSymBlock.sym;
        }

        currentSymBlock.lines.push(l);
      }

      linesPerSym.push(currentSymBlock);

      const matchingSymBlocks = (function findMatchingSymBlocks() {
        const isSymMatch = (b: LinesPerSym) => b.sym === contextSymbol;
        if (multiple) {
          return linesPerSym.filter(isSymMatch);
        }
        if (reverse) {
          const matches = linesPerSym.filter(isSymMatch);
          return matches.splice(-1);
        }
        const firstMatch = linesPerSym.find(isSymMatch);
        if (!firstMatch) return [];
        return [firstMatch];
      })();

      if (matchingSymBlocks.length === 0) {
        const symBlock = {
          sym: contextSymbol,
          lines: [
            `instrument ${contextSymbol}`,
            `### DEFINE ${symFriendlyName} aliases here`,
          ],
        };
        linesPerSym.push(symBlock);
        matchingSymBlocks.push(symBlock);
      }

      const isMatch = (l: string) => {
        const args = l
          .split(/\s+/)
          .map((a) => a.trim())
          .filter((a) => a !== "");

        for (let i = 0; i < lineMatchers.length; ++i) {
          if (
            args[i] === undefined ||
            (lineMatchers[i] !== null &&
              lineMatchers[i]?.toLowerCase() !==
              args[i].toLowerCase().replace(":", ""))
          ) {
            return false;
          }
        }
        return true;
      };

      const removeMarker = "#TOBEREMOVED#";

      matchingSymBlocks.forEach((symBlock) => {
        if (multiple) {
          for (const [k, v] of symBlock.lines.entries()) {
            if (isMatch(v)) {
              symBlock.lines[k] = cmdWords?.join(" ") ?? removeMarker;
            }
          }
          return;
        }
        if (reverse) symBlock.lines.reverse();

        const matchingLineIdx = symBlock.lines.findIndex(isMatch);

        if (matchingLineIdx > -1) {
          symBlock.lines[matchingLineIdx] = cmdWords?.join(" ") ?? removeMarker;
          if (reverse) symBlock.lines.reverse();
        } else if (cmdWords) {
          if (reverse) symBlock.lines.reverse();
          symBlock.lines.push(cmdWords.join(" "));
        } else {
          if (reverse) symBlock.lines.reverse();
        }
      });

      const newInitLines = flatten(linesPerSym.map((b) => b.lines)).filter(
        (l) => l !== removeMarker,
      );

      writeFileSync(filename, newInitLines.join("\n"));
    },
    [output],
  );

  const [currentQuestion, setCurrentQuestion] = useState<null | string>(null);

  const getInitFilePath = (exchange: ExchangeLabel): string =>
    `initrun.${exchange}.txt`;

  const initFilePaths = SUPPORTED_EXCHANGES.map(getInitFilePath);

  const clientOpts: IchibotClientOpts = useMemo(
    () => ({
      wsUrlA,
      process: {
        exit: () => {
          output.log(`Finished.`);
        },
      },
      clientDB: {
        push: (key, value) => {
          localStorage.setItem(key, JSON.stringify(value));
        },
        delete: (key) => {
          localStorage.removeItem(key);
        },
        filter: function <T>(
          root: string,
          fn: (item: T, key: string | number) => boolean,
        ): T[] | undefined {
          return Object.entries(localStorage)
            .filter(([key, _value]) => key.startsWith(root))
            .filter(([key, value]) =>
              fn(value, key.replace(new RegExp(`^${root}`), "")),
            )
            .map(([_key, value]) => JSON.parse(value));
        },
      },
      readInitFile: (exchange: ExchangeLabel) => {
        if (!exchange) {
          // Legacy. Currently not possible to create auth without exchange but old data may have it so
          throw new Error(
            `Could not find the name of the exchange from configuration`,
          );
        }

        const filename = getInitFilePath(exchange);

        const itemStr = localStorage.getItem(filename);
        return {
          initLines: itemStr !== null ? itemStr.split("\n") : [],
        };
      },
      getDataSafe: (path, defaultValue) => {
        const itemStr = localStorage.getItem(path);
        return itemStr === null ? defaultValue : JSON.parse(itemStr);
      },
      debug,
      saveCmdToInit,
      logger: output,
      io: {
        setPrompt: (p: string) => {
          setCurrentQuestion(p);
        },
      },
      binaryDecoder: async (payload) => {
        try {
          const msg = decodeMultiple(payload);
          if (!msg) {
            return [];
          }
          return (msg as { jsonrpc: string }[]).filter(
            (msg) => msg && typeof msg === "object" && msg.jsonrpc === "2.0",
          );
        } catch {
          // No need to send any decoding errors back to server
          return [];
        }
      },
      keyGen: null,
    }),
    [output, saveCmdToInit],
  );

  const client = useMemo(() => new MasterClient(clientOpts), [clientOpts]);

  useEffect(() => {
    client
      .start()
      .then(() => {
        output.log("The client finished");
      })
      .catch((err) => {
        output.error("The client terminated with an error", err);
      });
  }, [client, output]);

  const logEnd = useRef<HTMLDivElement>(null);
  useEffect(() => {
    logEnd.current?.scrollIntoView({ behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logLineCounter]);

  const promptRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (promptRef.current) {
      promptRef.current.focus();
    }
  }, []);

  const [currentEditorFileName, setEditorFileName] = useState("");

  function FileMenu(props: { fileList: string[] }) {
    const menuItems = props.fileList.map((fileName) => {
      return (
        <Menu.Item key={fileName}>
          <a
            href="/editor"
            onClick={(e) => {
              setEditorFileName(fileName);
              e.preventDefault();
            }}
          >
            {fileName}
          </a>
        </Menu.Item>
      );
    });
    return (
      <Menu theme="dark" selectedKeys={[currentEditorFileName]}>
        {menuItems}
      </Menu>
    );
  }

  class FileEditor extends React.Component<
    { fileName: string },
    { init: boolean; hasChanged: boolean }
  > {
    initialContents: string;
    contents: string;
    constructor(props: { fileName: string }) {
      super(props);
      this.initialContents = readFileSync(props.fileName);
      this.contents = this.initialContents;
      this.state = { init: true, hasChanged: false };
    }

    render() {
      if (this.props.fileName === "") {
        return <div>No file opened</div>;
      }
      return (
        <div style={{ textAlign: "left", width: "100%" }}>
          Current file: {this.props.fileName}&nbsp;
          {this.state.hasChanged ? (
            <button
              onClick={(e) => {
                writeFileSync(this.props.fileName, this.contents);
                this.initialContents = this.contents;
                this.setState({ init: true, hasChanged: false });
                e.preventDefault();
              }}
            >
              Save
            </button>
          ) : (
            ""
          )}
          &nbsp;
          <button
            onClick={(e) => {
              this.initialContents = readFileSync(this.props.fileName);
              this.contents = this.initialContents;
              this.setState({ init: true, hasChanged: false });
              e.preventDefault();
            }}
          >
            Refresh
          </button>
          <CodeMirror
            value={this.contents}
            onChanges={(instance, change) => {
              const i = instance as Editor;
              if (change.length > 0) {
                this.contents = i.getValue();
                if (this.state.init) {
                  if (this.initialContents === this.contents) {
                    (() => {
                      this.setState({ init: false });
                    })();
                  } else {
                    (() => {
                      this.setState({ init: false, hasChanged: true });
                    })();
                  }
                } else if (!this.state.hasChanged) {
                  (() => {
                    this.setState({ hasChanged: true });
                  })();
                }
              }
            }}
          />
        </div>
      );
    }
  }

  const Editor = props.showEditor
    ? () => (
      <div className={"Compartment"} style={{ height: "100%" }}>
        <FileMenu fileList={initFilePaths} />
        <FileEditor fileName={currentEditorFileName} />
      </div>
    )
    : () => <div></div>;

  const EditorLink = props.showEditor
    ? () => (
      <Link to="/">
        <span className={"broad"}>Initrun&nbsp;Editor&nbsp;❌</span>
        <span className={"narrow"}>❌</span>
      </Link>
    )
    : () => (
      <Link to="/editor">
        <span className={"broad"}>📝&nbsp;Initrun&nbsp;Editor</span>
        <span className={"narrow"}>📝</span>
      </Link>
    );

  const Head = props.miniView
    ? () => <div style={{ display: "none" }} />
    : () => {
      return (
        <Header style={{ width: "100%" }}>
          <Row>
            <Col span={20}>
              <Text style={{ color: "#eee" }}>
                Ichibot BETA {APP_VERSION}
              </Text>
            </Col>
            <Col span={4}>
              <EditorLink />
            </Col>
          </Row>
        </Header>
      );
    };

  return (
    <Layout
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        height: "100%",
      }}
    >
      <Head />

      <Editor></Editor>

      <div
        className={"Compartment"}
        style={{ background: "#111", height: "100%" }}
      >
        <div
          style={{
            alignSelf: "flex-end",
            textAlign: "left",
            paddingLeft: "1em",
            height: "100%",
          }}
        >
          {logLines.map(([i, d, line]) => (
            <div
              key={i}
              style={{
                marginBottom: "0.2em",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: LEFT_COL_WIDTH }}>{d}</div>
              <div>{line}</div>
            </div>
          ))}
          <div ref={logEnd} />
        </div>
      </div>

      <Footer
        style={{
          display: "flex",
          flexDirection: "row",
          boxSizing: "border-box",
          padding: "0.4em",
          alignItems: "center",
          paddingBottom: props.miniView ? "0.4em" : "1.5em",
        }}
      >
        <div
          style={{
            paddingRight: "1em",
            paddingLeft: "1em",
            fontSize: "1.2em",
            fontWeight: "bold",
          }}
        >
          {currentQuestion}
        </div>
        <input
          ref={promptRef}
          autoCapitalize="none"
          autoCorrect="off"
          style={{
            lineHeight: "1.5em",
            fontSize: "1.4em",
            flexGrow: 1,
            paddingLeft: "0.5em",
            marginRight: "1em",
          }}
          type="text"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const val = e.currentTarget.value;
              debug && console.log("Submit answer", val);
              e.currentTarget.value = "";
              client.sendInput(val);
            }
          }}
        />
        <div
          style={{
            height: "1em",
          }}
        ></div>
      </Footer>
    </Layout>
  );
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/mini">
          <Ichibot miniView={true} />
        </Route>
        <Route path="/editor">
          <Ichibot showEditor={true} />
        </Route>
        <Route path="/">
          <Ichibot />
        </Route>
      </Switch>
    </Router>
  );
}
