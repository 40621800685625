import {
  AccountMode,
  AuthRecord,
  ClassicAccount,
  ClientAuthArgs,
  GlobalContext,
} from "../shared/ichibotrpc_types";
import { ExchangeLabel } from "../shared/types";
import { APL, IS_NODEJS, SETTINGS_KEY } from "./constants";
import { IchibotClientOpts } from "./ichibotclient";
import { v4 as uuid } from "../uuid";
import * as R from "ramda";
import { DefaultClientSettings } from "./defaults";
import { ClientSettings, ClientSettingsLoginModes, Connection } from "./types";

export const noop = () => {
  // no op
};

export const getAuthSaveKey = (friendlyName: string): string =>
  `/auth-${friendlyName}`;
export const getCookieSaveKey = (
  serverUrl: string,
  exchangeFriendlyName: string,
): string =>
  `/cookie_${serverUrl.replace(/[:/.]/g, "_")}_${exchangeFriendlyName}`;
/*
  Generate websocket URL based on exchange
  */
export const generateWsUrl = (
  opts: IchibotClientOpts,
  exchange: ExchangeLabel,
) => {
  return (
    opts.wsUrlA +
    (opts.omitConnectionQueryString
      ? ""
      : `?primaryexchange=${exchange}&client-ws-library=hydrated-ws`)
  );
};

export const generateWsHeaders = (
  opts: IchibotClientOpts,
  exchange: ExchangeLabel,
  exchangeFriendlyName: string,
) => {
  const wsUrl = generateWsUrl(opts, exchange);
  const cookieSaveKey = getCookieSaveKey(wsUrl, exchangeFriendlyName);
  const currentCookie = IS_NODEJS
    ? opts.clientDB.filter(
        "/",
        (_item, key) => key === cookieSaveKey.replace("/", ""),
      )?.[0]
    : undefined;
  const wsHeaders: Record<string, string> = {
    "client-ws-library": "hydrated-ws",
    "X-exchange": exchange,
  };
  if (typeof currentCookie === "string") {
    wsHeaders.Cookie = currentCookie;
  }
  return wsHeaders;
};

export const getPromptText = (context: GlobalContext | null | false) => {
  if (context === null) {
    return "[not ready] ";
  }
  if (context === false) {
    return "[not logged in] ";
  }
  return context.currentInstrument
    ? `[${context.currentInstrument}] > `
    : `[global *] > `;
};

export const refreshPrompt = (
  opts: IchibotClientOpts,
  context: GlobalContext | null | false,
) => {
  opts.io.setPrompt(getPromptText(context), true);
};

export const ensureClientSettings = (opts: IchibotClientOpts) => {
  const clientSettings = opts.clientDB.filter("/", (_item: unknown, key) => {
    return typeof key === "string" && key === SETTINGS_KEY;
  })?.[0];
  if (typeof clientSettings !== "object" || !clientSettings) {
    opts.logger.log(`Loading default client settings`);
    opts.clientDB.push(`/${SETTINGS_KEY}`, DefaultClientSettings);
    return DefaultClientSettings;
  }
  const tryCs = clientSettings as ClientSettings;
  const loginMode = (function checkLoginMode() {
    if (typeof tryCs.loginMode === "string") {
      const validMode = ClientSettingsLoginModes.includes(tryCs.loginMode);
      if (validMode) {
        return tryCs.loginMode;
      }
      opts.logger.log(`Invalid setting loginMode: ${tryCs.loginMode}`);
    } else if (typeof tryCs.loginMode !== "undefined") {
      opts.logger.log(`Invalid setting for loginMode`);
    }
    opts.logger.log(
      `Setting default loginMode: ${DefaultClientSettings.loginMode}`,
    );
    tryCs.loginMode = DefaultClientSettings.loginMode;
    opts.clientDB.push(`/${SETTINGS_KEY}`, tryCs);
    return DefaultClientSettings.loginMode;
  })();
  const logTimestamps = (function checkLogTimestamps() {
    if (typeof tryCs.logTimestamps === "boolean") {
      return tryCs.logTimestamps;
    }
    if (typeof tryCs.logTimestamps !== "undefined") {
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      opts.logger.log(`Invalid setting logTimestamps: ${tryCs.logTimestamps}`);
      opts.logger.log(
        `Setting default logTimestamps: ${DefaultClientSettings.logTimestamps}`,
      );
      /* eslint-enable @typescript-eslint/restrict-template-expressions */
      // If it is undefined we can update it silently
    }
    tryCs.logTimestamps = DefaultClientSettings.logTimestamps;
    opts.clientDB.push(`/${SETTINGS_KEY}`, tryCs);
    return DefaultClientSettings.logTimestamps;
  })();
  const cs: ClientSettings = {
    loginMode,
    logTimestamps,
  };
  return cs;
};

export const ensureClientId = (opts: IchibotClientOpts): string => {
  const CLIENT_ID_KEY = "client-id";
  let clientId = opts.clientDB.filter(
    "/",
    (_item: string, key) => typeof key === "string" && key === CLIENT_ID_KEY,
  )?.[0];
  if (!clientId) {
    clientId = uuid();
    opts.clientDB.push(`/${CLIENT_ID_KEY}`, clientId);
  }
  return clientId;
};

export const saveAuth = (opts: IchibotClientOpts, auth: ClientAuthArgs) => {
  const key = getAuthSaveKey(auth.friendlyName);
  opts.clientDB.push(key, auth);
};

export const deleteAuth = (opts: IchibotClientOpts, auth: ClientAuthArgs) => {
  const key = getAuthSaveKey(auth.friendlyName);
  opts.clientDB.delete(key);
};

export const deleteAuthByName = (
  opts: IchibotClientOpts,
  name: ClientAuthArgs["friendlyName"],
) => {
  const key = getAuthSaveKey(name);
  opts.clientDB.delete(key);
};

export const getAuths = (opts: IchibotClientOpts) => {
  return (
    opts.clientDB.filter(
      "/",
      (_item: ClientAuthArgs, key) =>
        typeof key === "string" && key.startsWith("auth"),
    ) ?? []
  );
};

export const isClassic = (obj: Record<"mode", unknown>) =>
  obj.mode === "classic" || obj.mode === undefined;

const whereClassic: (x: ClassicAccount) => boolean = R.where({
  mode: R.anyPass([R.equals(undefined), R.equals("classic")]),
  exchange: R.is(String),
  apiKey: R.is(String),
  apiSecret: R.is(String),
  friendlyName: R.is(String),
});

export const verifyClassicAuth = (x: ClientAuthArgs): x is ClassicAccount => {
  return whereClassic(x as ClassicAccount);
};

export const classicAccountToAuth = (account: ClassicAccount) => {
  const auth: AuthRecord = {
    exchange: account.exchange,
    apiKey: account.apiKey,
    apiSecret: account.apiSecret,
    passphrase: account.passphrase ?? "",
    subAccount: account.subAccount ?? "",
  };
  for (const ap of APL) {
    const payload = account[ap];
    if (payload !== undefined && typeof payload === "string") {
      auth[ap] = payload;
    }
  }
  return auth;
};

export const getAuthFromStore = (
  opts: IchibotClientOpts,
  name?: string,
  mode?: AccountMode,
): ClientAuthArgs | null => {
  return _getAuthFromStore(false, opts, name, mode);
};

export const startupGetAuthFromStore = (
  opts: IchibotClientOpts,
  name?: string,
  mode?: AccountMode,
): ClientAuthArgs | null => {
  return _getAuthFromStore(true, opts, name, mode);
};

const _getAuthFromStore = (
  startup: boolean,
  opts: IchibotClientOpts,
  name?: string,
  mode?: AccountMode,
): ClientAuthArgs | null => {
  let potentialAuths = getAuths(opts);

  if (potentialAuths.length === 0) return null;

  if (mode) {
    const filter0 = R.filter<(typeof potentialAuths)[number]>(isClassic);
    const filter1 = R.filter(R.propEq(mode, "mode"));
    const filterN = mode === "classic" ? filter0 : filter1;
    potentialAuths = filterN(potentialAuths);
  }

  if (potentialAuths.length === 0) {
    /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
    opts.logger.warn(`Unable to find any entries for mode: ${mode}`);
    return null;
  }

  if (name) {
    const matchedByName = potentialAuths.find(
      (auth: ClientAuthArgs) => auth.friendlyName === name,
    );
    if (matchedByName) {
      return matchedByName;
    }
    if (!startup) {
      return null;
    }
    const matchDefault = potentialAuths.find(
      (auth: ClientAuthArgs) => auth.friendlyName === "default",
    );
    if (matchDefault) {
      opts.logger.warn(
        `Could not find entry for ${name}, loading default account`,
      );
      return matchDefault;
    }
    const firstEntry = potentialAuths[0];
    opts.logger.warn(
      `Could not find entry for ${name}, loading ${firstEntry.friendlyName}`,
    );
    return firstEntry;
  }

  const matchDefault = potentialAuths.find(
    (auth: ClientAuthArgs) => auth.friendlyName === "default",
  );
  if (matchDefault) {
    return matchDefault;
  }
  const firstEntry = potentialAuths[0];
  return firstEntry;
};

export const getLastContext = (c: Connection) => {
  const { context } = c.conn.single;
  return context;
};
