import { ParameterType } from "./util";
import { ExchangeLabel } from "./types";
import { PARENT_AUTH_MODE } from "../client/constants";

export const APP_VERSION = "19.12.2";

export const ALL_SYM = "*";
export const symFriendlyName = (s: string | null): string =>
  s === null || s === "*" ? "global" : s;

export interface GlobalContext {
  currentInstrument: string | null;
}

export interface DebuggableArgs {
  debug?: boolean;
}

export type AccountMode =
  | undefined
  | ClassicAccount["mode"]
  | SemarAccounts["mode"];

type ClassicMode = "classic";
type SemarMode = "semar";

export interface ClassicAccount extends Record<string, unknown> {
  mode: ClassicMode | undefined;
  exchange: ExchangeLabel;
  apiKey: string;
  apiSecret: string;
  passphrase?: string;
  subAccount?: string | null;
  friendlyName: string;
  quantityMultiplier?: number;
  [PARENT_AUTH_MODE]?: "semar";
}

export interface AuthRecord extends Record<string, string> {
  exchange: ExchangeLabel;
  apiKey: string;
  apiSecret: string;
  passphrase: string;
  subAccount: string;
}

interface AuthArgs {
  auth: AuthRecord;
}

export type ClientAuthArgs = ClassicAccount | SemarAccounts;

export type AuthMode = ClassicMode | SemarMode;

export interface SemarAccount {
  apiKey: string;
  apiSecret: string;
  passphrase?: string;
  subAccount?: string | null;
  delay: number;
  quantityMultiplier?: number;
}

export interface SemarAccounts extends Record<string, unknown> {
  mode: SemarMode;
  exchange: ExchangeLabel;
  friendlyName: string;
  accounts: SemarAccount[];
}

export interface ClientArgs {
  clientId?: string;
}

export interface CommonArgs extends DebuggableArgs, AuthArgs, ClientArgs {
  context: GlobalContext | null;
}

export type AliasMapping = Record<string, string>;

export interface IchibotRPC {
  hello: (
    args: {
      name: string;
      version: string;
      initLines: string[];
      quantityMultiplier?: number;
    } & CommonArgs,
  ) => Promise<{
    instanceStarted: boolean;
    version: number | string;
  }>;
  reloadInit: (
    args: { initLines: string[] } & CommonArgs,
  ) => Promise<{ ok: boolean }>;
  poke: (
    args: Record<string, unknown> & CommonArgs,
  ) => Promise<Record<string, unknown>>;
  bye: (args: Record<string, unknown> & CommonArgs) => Promise<{
    ok: boolean;
  }>;
  getCompletions: (args: CommonArgs) => Promise<{
    completions: [string[], string];
  }>;
  rawcmd: (args: { cmd: string } & CommonArgs) => Promise<{
    message?: string;
    success: boolean;
  }>;
}

export interface ContextNotification {
  notification: "context";
  params: GlobalContext;
}

export interface MessageNotification {
  notification: "feed";
  params: {
    message: [unknown, ...unknown[]];
    type: "log" | "error" | "warn" | "dir" | "debug";
  };
}

export interface InstructionNotification {
  notification: "instruction";
  params: {
    instruction: "force-disconnect";
    reason: string;
  };
}

export type IchibotRPCNotification =
  | MessageNotification
  | ContextNotification
  | InstructionNotification;

export interface RPCRequest<T extends keyof IchibotRPC> {
  jsonrpc: "2.0";
  method: T;
  params: ParameterType<IchibotRPC[T]>;
  id?: string | number | null;
}

export interface RPCResponse<T extends keyof IchibotRPC> {
  jsonrpc: "2.0";
  result: ReturnType<IchibotRPC[T]>;
  id?: string | number | null;
}

export interface RPCError {
  jsonrpc: "2.0";
  error: {
    code: number;
    message: string;
    data?: unknown;
  };
  id?: string | number | null;
}
