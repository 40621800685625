import {
  AuthMode,
  ClientAuthArgs,
  SemarAccount,
} from "../shared/ichibotrpc_types";
import { ExchangeLabel } from "../shared/types";
import { IchibotClientOpts } from "./ichibotclient";
import * as R from "ramda";

type matcher<T> = (b: unknown) => b is T;
function equal<T>(a: T): matcher<T> {
  return R.equals(a) as matcher<T>;
}

function singleCharMatcher<T>(i: readonly T[]): (s: string) => T | false {
  const eqs: matcher<T>[] = R.map<T, matcher<T>>(equal, i);
  const anyP = R.anyPass(eqs);
  return (s: string) => {
    if (s.length === 0) {
      return false;
    }
    const firstChar = s[0];
    const lowerCase = firstChar.toLowerCase();
    const res = anyP(lowerCase);
    if (res) {
      return lowerCase;
    }
    return false;
  };
}

function stringMatcher(s: string): string | false {
  if (!R.is(String, s)) return false;
  const t = s.trim();
  if (R.isEmpty(t)) return false;
  return t;
}

function stringMatcherAllowEmpty(s: string): string | false {
  if (!R.is(String, s)) return false;
  const t = s.trim();
  if (R.isEmpty(t)) return "";
  return t;
}

function numberMatcherAllowEmpty(s: string): number | false {
  if (!R.is(String, s)) return false;
  const n = +s;
  if (!Number.isFinite(n)) return false;
  return n;
}

export default class QueryManager {
  query: (q: string) => Promise<string>;
  private queryAnswersBuffer: string[] = [];
  private queryResolvesBuffer: ((r: string) => void)[] = [];
  constructor(opts: IchibotClientOpts) {
    if (opts.io.query) {
      this.query = opts.io.query;
    } else {
      this.query = (q: string): Promise<string> => {
        if (this.queryAnswersBuffer.length > 0) {
          const answer = this.queryAnswersBuffer.shift();
          if (answer === undefined) {
            return Promise.reject(
              new Error(`Should not have happened, answer undefined`),
            );
          }
          return Promise.resolve(answer);
        } else {
          opts.io.setPrompt(q);
          return new Promise((resolve) => {
            this.queryResolvesBuffer.push(resolve);
          });
        }
      };
    }
  }
  sendInput(msg: string) {
    if (this.queryResolvesBuffer.length > 0) {
      const resolve = this.queryResolvesBuffer.shift();
      if (resolve === undefined) {
        throw new Error(`Should not have happened, resolve undefined`);
      }
      resolve(msg);
    } else {
      this.queryAnswersBuffer.push(msg);
    }
  }
  private async requestUntilValid<T>(
    msg: string,
    validator: (s: string) => T | false,
  ): Promise<T> {
    let answer: string;
    let result: T | false;
    do {
      answer = (await this.query(msg)).trim();
      result = validator(answer);
    } while (result === false);
    return result;
  }
  private async requestExchange(): Promise<ExchangeLabel> {
    const exchangeLabelAnswers = ["b", "s", "p"] as const;
    type eLA = (typeof exchangeLabelAnswers)[number];
    const exchangeLabelAnswerMap: { [k in eLA]: ExchangeLabel } = {
      b: "binance",
      s: "binance-spot",
      p: "binance-pm",
    };
    const exchangeLabelAnswer: eLA = await this.requestUntilValid<eLA>(
      "Exchange: (b)inance futs, binance (p)ortfolio-margin, binance (s)pot: ",
      singleCharMatcher<eLA>(exchangeLabelAnswers),
    );
    return exchangeLabelAnswerMap[exchangeLabelAnswer];
  }
  private async requestMode(): Promise<AuthMode> {
    const modeAnswers = ["c", "s"] as const;
    type mA = (typeof modeAnswers)[number];
    const modeAnswerMap: { [k in mA]: AuthMode } = {
      c: "classic",
      s: "semar",
    };
    const modeAnswer = await this.requestUntilValid(
      "Mode: (c)lassic, (s)emar: ",
      singleCharMatcher<mA>(modeAnswers),
    );
    return modeAnswerMap[modeAnswer];
  }
  private async requestApiKey() {
    return await this.requestUntilValid("Your API key: ", stringMatcher);
  }
  private async requestApiSecret() {
    return await this.requestUntilValid("Your API secret: ", stringMatcher);
  }
  private requestSubAccount() {
    return Promise.resolve("");
  }
  private requestPassphrase() {
    return Promise.resolve("");
  }
  private async requestFriendlyName() {
    return (
      (await this.requestUntilValid(
        'Name these credentials (empty = "default"): ',
        stringMatcherAllowEmpty,
      )) || "default"
    );
  }
  private async requestDelay() {
    return await this.requestUntilValid(
      "Input a delay in milliseconds (empty = 0): ",
      numberMatcherAllowEmpty,
    );
  }
  private async requestAnother() {
    const answer = (await this.query("Add another (y/N)? ")).trim();
    return answer === "y" ? true : false;
  }
  async requestLogin(): Promise<ClientAuthArgs> {
    const exchange = await this.requestExchange();
    const mode = await this.requestMode();
    switch (mode) {
      case "classic": {
        const apiKey = await this.requestApiKey();
        const apiSecret = await this.requestApiSecret();
        const subAccount = await this.requestSubAccount();
        const passphrase = await this.requestPassphrase();
        const friendlyName = await this.requestFriendlyName();
        return {
          mode,
          apiKey,
          apiSecret,
          passphrase,
          subAccount,
          exchange,
          friendlyName,
        };
      }
      case "semar": {
        const friendlyName = await this.requestFriendlyName();
        const accounts: SemarAccount[] = [];
        let another = true;
        while (another) {
          const apiKey = await this.requestApiKey();
          const apiSecret = await this.requestApiSecret();
          const subAccount = await this.requestSubAccount();
          const passphrase = await this.requestPassphrase();
          const delay = await this.requestDelay();
          accounts.push({ apiKey, apiSecret, subAccount, passphrase, delay });
          another = await this.requestAnother();
        }
        return {
          mode,
          exchange,
          friendlyName,
          accounts,
        };
      }
    }
  }
}
